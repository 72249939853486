/**
 * バリデーションメッセージ一覧
 */
export const YupErrorMessage = {
  required: '${path} を入力してください。',
  string: {
    length: '${path} は ${length} 文字で入力してください。',
    min: '${path} は ${min} 文字以上入力してください。',
    max: '${path} は ${max} 文字以下で入力してください。',
    email: 'メールアドレスの形式が不正です。',
    url: 'URLの形式が不正です。',
    lowercase: '${path} は小文字で入力してください。',
    uppercase: '${path} は大文字で入力してください。',
    hiragana_zen: '${path} は全角ひらがなで入力してください。',
    phoneNumber: '0から始まる10〜11桁の数字で入力してください。',
    pw: 'パスワードに利用可能な文字は半角数字、半角英小文字、半角英大文字、および特殊文字 ^$*.[]{}()?-"!@#%$/,><\':;|_~`+= です。',
    pwConfirm: 'パスワード入力とパスワード確認入力が一致しません。',
    pw_email_matches: '登録メールアドレスとは異なるパスワードを設定してください。',
    verifyCode: '認証コードは6桁の数字で入力してください。',
    phone: '11桁以内の半角数字で入力してください。',
    should_not_career_mail: 'キャリアメールはご登録いただけません。',
    multi_byte_character_not_accepted: 'マルチバイト文字は受け入れられません',
    registered_email: 'このメールアドレスではご登録いただけません。',
    story: '所在階はB1〜B5, -5〜99の範囲で入力してください。',
  },
  number: {
    min: '${path} は ${min} 以上の数値を入力してください。',
    max: '${path} は ${max} 以下の数値を入力してください。',
    lessThan: '${path} は ${lessThan} 未満の数値を入力してください。',
    moreThan: '${path} は ${more} より大きい数値を入力してください。',
    positive: '${path} は正の数を入力してください。',
    negative: '${path} は負の数を入力してください。',
    integer: '${path} は整数を入力してください。',
    numeric: '${path} は数値を入力してください。',
    digits: '${path}は 小数点 ${digits} 桁以内で入力してください。',
  },
  date: {
    min: '${path} は ${min} 以降を指定してください。',
    max: '${path} は ${max} より前を指定してください。',
    typeError: '${path} は 正しい日付で入力してください',
  },
} as const;

/**
 * 存在しない年月日の入力チェックエラー
 */
export const INVALID_YYYYMMDD = '存在しない年月日です';
