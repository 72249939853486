import { createAction, createAsyncAction } from 'typesafe-actions';
import {
  CLEAR_ASSESSMENT_FORM_VALUE,
  CreateAssessment,
  KEEP_ASSESSMENT_FORM_VALUE,
  SET_PERSONAL_INFO_VALUE,
  CLEAR_PERSONAL_INFO_VALUE,
} from '@/state/modules/assessment/types';
import { PersonalInfoValueState as PersonalInfoValue } from '@/state/modules/assessment/reducers';
import { Assessment } from 'openapi/kusabi-frontgw-spec';
import { AssessmentFormValue } from '@/models/request/assessment';

const createAssessment = createAsyncAction(CreateAssessment.REQUEST, CreateAssessment.SUCCESS, CreateAssessment.FAILED)<
  {
    assessment: Assessment;
  },
  { result: Assessment },
  { error: Error }
>();

const keepAssessmentFormValue = createAction(
  KEEP_ASSESSMENT_FORM_VALUE,
  (assessmentFormValue: AssessmentFormValue) => assessmentFormValue
)<AssessmentFormValue>();

const clearAssessmentFormValue = createAction(CLEAR_ASSESSMENT_FORM_VALUE, () => undefined)();

const setPersonalInfoValue = createAction(
  SET_PERSONAL_INFO_VALUE,
  (personalInfoValue: PersonalInfoValue) => personalInfoValue
)<PersonalInfoValue>();

const clearPersonalInfoValue = createAction(CLEAR_PERSONAL_INFO_VALUE, () => null)();

export default {
  createAssessment,
  setPersonalInfoValue,
  keepAssessmentFormValue,
  clearAssessmentFormValue,
  clearPersonalInfoValue,
};
