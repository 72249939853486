import { createAsyncAction } from 'typesafe-actions';
import { PostInquiries, UpdateInquiries } from '@/state/modules/inquiry/types';
import { Inquiry, UpdateInquiryRequest } from 'openapi/kusabi-frontgw-spec';

const postInquiries = createAsyncAction(PostInquiries.REQUEST, PostInquiries.SUCCESS, PostInquiries.FAILED)<
  {
    params: Inquiry;
  },
  {
    result: Inquiry;
  },
  { error: Error }
>();

const updateInquiries = createAsyncAction(UpdateInquiries.REQUEST, UpdateInquiries.SUCCESS, UpdateInquiries.FAILED)<
  {
    inquiryId: number;
    updateInquiryRequest: UpdateInquiryRequest;
  },
  {
    result: Inquiry;
  },
  { error: Error }
>();

export default {
  postInquiries,
  updateInquiries,
};
