export enum GetSaleProperties {
  REQUEST = '@buyer/getSaleProperties/request',
  SUCCESS = '@buyer/getSaleProperties/success',
  FAILED = '@buyer/getSaleProperties/failed',
}

export enum GetContractProperties {
  REQUEST = '@buyer/getContractProperties/request',
  SUCCESS = '@buyer/getContractProperties/success',
  FAILED = '@buyer/getContractProperties/failed',
}

export enum GetContractPropertyDetail {
  REQUEST = '@buyer/getContractPropertyDetail/request',
  SUCCESS = '@buyer/getContractPropertyDetail/success',
  FAILED = '@buyer/getContractPropertyDetail/failed',
}

export enum GetSalePropertyDetail {
  REQUEST = '@buyer/getSalePropertyDetail/request',
  SUCCESS = '@buyer/getSalePropertyDetail/success',
  FAILED = '@buyer/getSalePropertyDetail/failed',
}

export enum GetAreaMappings {
  REQUEST = '@buyer/getAreaMappings/request',
  SUCCESS = '@buyer/getAreaMappings/success',
  FAILED = '@buyer/getAreaMappings/failed',
}

/***************************
 会員登録画面
 ***************************/

export const CHANGE_BUYER_MEMBER_REGISTER_FORM = '@buyer/changeBuyerMemberRegisterForm';

/**
 * 未ログイン状態で直指定した売出し中物件詳細画面URL
 */
export const SAVE_PATH = `@buyer/savePath`;

export const CHANGE_TRIAL_MODE = `@buyer/changeTrialMode`;
