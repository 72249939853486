import { createAction, createAsyncAction } from 'typesafe-actions';
import {
  CHANGE_BUYER_MEMBER_REGISTER_FORM,
  GetContractProperties,
  GetSaleProperties,
  GetContractPropertyDetail,
  GetSalePropertyDetail,
  SAVE_PATH,
  GetAreaMappings,
  CHANGE_TRIAL_MODE,
} from '@/state/modules/buyer/types';
import { GetSalePropertiesParam, GetContractPropertiesParam } from '@/models/request/buyer';
import { BuyerUserInfoState, SavePathState } from './reducers';
import { ContractsProperties, Property, SalesProperties, AreaMappings } from 'openapi/kusabi-frontgw-spec';

const getSaleProperties = createAsyncAction(
  GetSaleProperties.REQUEST,
  GetSaleProperties.SUCCESS,
  GetSaleProperties.FAILED
)<
  {
    params: GetSalePropertiesParam;
  },
  { result: SalesProperties },
  { error: Error }
>();

const getContractProperties = createAsyncAction(
  GetContractProperties.REQUEST,
  GetContractProperties.SUCCESS,
  GetContractProperties.FAILED
)<
  {
    params: GetContractPropertiesParam;
  },
  { result: ContractsProperties },
  { error: Error }
>();

const changeAdvertiserValue = createAction(
  CHANGE_BUYER_MEMBER_REGISTER_FORM,
  (formValues: BuyerUserInfoState) => formValues
)<BuyerUserInfoState>();

const getContractPropertyDetail = createAsyncAction(
  GetContractPropertyDetail.REQUEST,
  GetContractPropertyDetail.SUCCESS,
  GetContractPropertyDetail.FAILED
)<{ propertyId: number }, { result: Property }, { error: Error }>();

const getSalePropertyDetail = createAsyncAction(
  GetSalePropertyDetail.REQUEST,
  GetSalePropertyDetail.SUCCESS,
  GetSalePropertyDetail.FAILED
)<{ propertyId: number }, { result: Property }, { error: Error }>();

const savePath = createAction(SAVE_PATH, (params: SavePathState) => params)<SavePathState>();

const getAreaMappings = createAsyncAction(GetAreaMappings.REQUEST, GetAreaMappings.SUCCESS, GetAreaMappings.FAILED)<
  { '': '' },
  { result: AreaMappings },
  { error: Error }
>();

const changeTrialMode = createAction(CHANGE_TRIAL_MODE, (isTrialMode: boolean) => isTrialMode)();

export default {
  changeAdvertiserValue,
  getSaleProperties,
  getContractProperties,
  getContractPropertyDetail,
  getSalePropertyDetail,
  savePath,
  getAreaMappings,
  changeTrialMode,
};
