import { apply, put, takeLatest } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import actions from '@/state/modules/loanableAmounts/actions';
import * as apis from '@/apis/gateway';
import { AxiosResponse } from 'axios';
import { LoanableAmountsProperty } from 'openapi/kusabi-frontgw-spec';

function* getLoanableAmountsAsync(action: ReturnType<typeof actions.getLoanableAmounts.request>) {
  try {
    const param = action.payload.params;
    const result: AxiosResponse<LoanableAmountsProperty, any> = yield apply(
      apis.loanableAmountsApi,
      apis.loanableAmountsApi.getLoanableAmounts,
      [param.annualIncome, param.totalLoanAmount, param.age]
    );
    yield put(actions.getLoanableAmounts.success({ result: result.data }));
  } catch (err: any) {
    console.log(err);
    yield put(actions.getLoanableAmounts.failure({ error: new Error(err.message) }));
  }
}

const sagas = [takeLatest(getType(actions.getLoanableAmounts.request), getLoanableAmountsAsync)];

export default sagas;
