import actions from '@/state/modules/member/actions';
import { Member } from 'openapi/kusabi-frontgw-spec';

const createMember = (params: Member) => actions.createMember.request({ params });

const getMember = () => actions.getMember.request({ '': '' });

const updateMember = (params: Member) => actions.updateMember.request({ params });

const clearMember = () => actions.updateMember.cancel({ '': '' }, { '': '' });

const deleteMember = () => actions.deleteMember.request();

const getIsRegisteredMailAddress = (params: { mailAddress: string }) =>
  actions.getIsRegisteredMailAddress.request({ params });

const clearIsRegisteredMailAddress = () => actions.getIsRegisteredMailAddress.cancel({ '': '' }, { '': '' });

export default {
  createMember,
  getMember,
  updateMember,
  clearMember,
  deleteMember,
  getIsRegisteredMailAddress,
  clearIsRegisteredMailAddress,
};
