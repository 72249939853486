import { createAsyncAction } from 'typesafe-actions';
import { GetLoanableAmounts } from '@/state/modules/loanableAmounts/types';
import { LoanableAmountsProperty } from 'openapi/kusabi-frontgw-spec';
import { GetLoanableAmountsStateParam } from '@/models/request/loanableAmounts';

const getLoanableAmounts = createAsyncAction(
  GetLoanableAmounts.REQUEST,
  GetLoanableAmounts.SUCCESS,
  GetLoanableAmounts.FAILED
)<
  {
    params: GetLoanableAmountsStateParam;
  },
  { result: LoanableAmountsProperty },
  { error: Error }
>();

export default {
  getLoanableAmounts,
};
