import { apply, put, takeLatest } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import actions from '@/state/modules/doorman/actions';
import * as apis from '@/apis/gateway';
import {
  DoormanTowns,
  DoormanCities,
  DoormanMansions,
  DoormanUnits,
  DoormanLines,
  DoormanStations,
} from 'openapi/kusabi-frontgw-spec';
import { AxiosResponse } from 'axios';

function* searchMansionsAsync(action: ReturnType<typeof actions.searchMansions.request>) {
  try {
    const result: AxiosResponse<DoormanMansions, any> = yield apply(apis.doormanApi, apis.doormanApi.searchMansions, [
      action.payload.params,
    ]);
    yield put(actions.searchMansions.success({ result: result.data }));
  } catch (err: any) {
    console.log(err);
    yield put(actions.searchMansions.failure({ error: new Error(err.message) }));
  }
}

function* getCitiesAsync(action: ReturnType<typeof actions.getCities.request>) {
  try {
    const result: AxiosResponse<DoormanCities, any> = yield apply(apis.doormanApi, apis.doormanApi.getCities, [
      action.payload.params,
    ]);
    yield put(actions.getCities.success({ result: result.data }));
  } catch (err: any) {
    console.log(err);
    yield put(actions.getCities.failure({ error: new Error(err.message) }));
  }
}

function* getTownsAsync(action: ReturnType<typeof actions.getTowns.request>) {
  try {
    const result: AxiosResponse<DoormanTowns, any> = yield apply(apis.doormanApi, apis.doormanApi.getTowns, [
      action.payload.params,
    ]);
    yield put(actions.getTowns.success({ result: result.data }));
  } catch (err: any) {
    console.log(err);
    yield put(actions.getTowns.failure({ error: new Error(err.message) }));
  }
}

function* getUnitsAsync(action: ReturnType<typeof actions.getUnits.request>) {
  try {
    const result: AxiosResponse<DoormanUnits, any> = yield apply(apis.doormanApi, apis.doormanApi.getUnits, [
      action.payload.params,
    ]);
    yield put(actions.getUnits.success({ result: result.data }));
  } catch (err: any) {
    console.log(err);
    yield put(actions.getUnits.failure({ error: new Error(err.message) }));
  }
}

function* getLinesAsync(action: ReturnType<typeof actions.getLines.request>) {
  try {
    const result: AxiosResponse<DoormanLines, any> = yield apply(apis.doormanApi, apis.doormanApi.getLines, [
      action.payload.params,
    ]);
    yield put(actions.getLines.success({ result: result.data }));
  } catch (err: any) {
    console.log(err);
    yield put(actions.getLines.failure({ error: new Error(err.message) }));
  }
}

function* getStationsAsync(action: ReturnType<typeof actions.getStations.request>) {
  try {
    const result: AxiosResponse<DoormanStations, any> = yield apply(apis.doormanApi, apis.doormanApi.getStations, [
      action.payload.params,
    ]);
    yield put(actions.getStations.success({ result: result.data }));
  } catch (err: any) {
    console.log(err);
    yield put(actions.getStations.failure({ error: new Error(err.message) }));
  }
}

const sagas = [
  takeLatest(getType(actions.searchMansions.request), searchMansionsAsync),
  takeLatest(getType(actions.getCities.request), getCitiesAsync),
  takeLatest(getType(actions.getTowns.request), getTownsAsync),
  takeLatest(getType(actions.getUnits.request), getUnitsAsync),
  takeLatest(getType(actions.getLines.request), getLinesAsync),
  takeLatest(getType(actions.getStations.request), getStationsAsync),
];

export default sagas;
