export enum SearchMansions {
  REQUEST = '@doorman/searchMansions/request',
  SUCCESS = '@doorman/searchMansions/success',
  FAILED = '@doorman/searchMansions/failed',
}

export enum GetCities {
  REQUEST = '@doorman/getCities/request',
  SUCCESS = '@doorman/getCities/success',
  FAILED = '@doorman/getCities/failed',
}

export enum GetTowns {
  REQUEST = '@doorman/getTowns/request',
  SUCCESS = '@doorman/getTowns/success',
  FAILED = '@doorman/getTowns/failed',
}

export enum GetUnits {
  REQUEST = '@doorman/getUnits/request',
  SUCCESS = '@doorman/getUnits/success',
  FAILED = '@doorman/getUnits/failed',
}

export enum GetLines {
  REQUEST = '@doorman/getLines/request',
  SUCCESS = '@doorman/getLines/success',
  FAILED = '@doorman/getLines/failed',
}

export enum GetStations {
  REQUEST = '@doorman/getStations/request',
  SUCCESS = '@doorman/getStations/success',
  FAILED = '@doorman/getStations/failed',
}
