export enum CreateAssessment {
  REQUEST = '@buyer/createAssessment/request',
  SUCCESS = '@buyer/createAssessment/success',
  FAILED = '@buyer/createAssessment/failed',
}

export const KEEP_ASSESSMENT_FORM_VALUE = '@assessment/keepAssessmentFormValue';

export const CLEAR_ASSESSMENT_FORM_VALUE = '@assessment/clearAssessmentFormValue';

export const SET_PERSONAL_INFO_VALUE = '@assessment/setPersonalInfoValue';

export const CLEAR_PERSONAL_INFO_VALUE = '@assessment/clearPersonalInfoValue';
