import { apply, put, takeLatest } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import actions from '@/state/modules/member/actions';
import * as apis from '@/apis/gateway';
import { Member, ResponseRegisterMailAddress } from 'openapi/kusabi-frontgw-spec';
import { AxiosResponse } from 'axios';

function* createMemberAsync(action: ReturnType<typeof actions.createMember.request>) {
  try {
    const result: AxiosResponse<Member, any> = yield apply(apis.memberApi, apis.memberApi.createMember, [
      action.payload.params,
    ]);
    yield put(actions.createMember.success({ result: result.data }));
  } catch (err: any) {
    console.log(err);
    yield put(actions.createMember.failure({ error: new Error(err.message) }));
  }
}

function* getMemberAsync() {
  try {
    const result: AxiosResponse<Member, any> = yield apply(apis.memberApi, apis.memberApi.getMember, []);
    yield put(actions.getMember.success({ result: result.data }));
  } catch (err: any) {
    console.log(err);
    yield put(actions.getMember.failure({ error: new Error(err.message) }));
  }
}

function* updateMemberAsync(action: ReturnType<typeof actions.updateMember.request>) {
  try {
    const result: AxiosResponse<Member, any> = yield apply(apis.memberApi, apis.memberApi.updateMember, [
      action.payload.params,
    ]);
    yield put(actions.updateMember.success({ result: result.data }));
  } catch (err: any) {
    console.log(err);
    yield put(actions.updateMember.failure({ error: new Error(err.message) }));
  }
}

function* deleteMemberAsync() {
  try {
    yield apply(apis.memberApi, apis.memberApi.deleteMemberLogically, []);
    yield put(actions.deleteMember.success());
  } catch (err: any) {
    console.log(err);
    yield put(actions.deleteMember.failure({ error: new Error(err.message) }));
  }
}

function* getIsRegisteredMailAddressAsync(action: ReturnType<typeof actions.getIsRegisteredMailAddress.request>) {
  try {
    const result: AxiosResponse<ResponseRegisterMailAddress, any> = yield apply(
      apis.memberApi,
      apis.memberApi.getMemberRegisteredMailAddress,
      [action.payload.params.mailAddress]
    );
    yield put(actions.getIsRegisteredMailAddress.success({ result: result.data }));
  } catch (err: any) {
    console.log(err);
    yield put(actions.getIsRegisteredMailAddress.failure({ error: new Error(err.message) }));
  }
}

const sagas = [
  takeLatest(getType(actions.createMember.request), createMemberAsync),
  takeLatest(getType(actions.getMember.request), getMemberAsync),
  takeLatest(getType(actions.updateMember.request), updateMemberAsync),
  takeLatest(getType(actions.deleteMember.request), deleteMemberAsync),
  takeLatest(getType(actions.getIsRegisteredMailAddress.request), getIsRegisteredMailAddressAsync),
];

export default sagas;
