import { RootState } from '@/state/store';

const getDoormanMansions = (state: RootState) => state.doorman.doormanMansion.entities.list;

const getDoormanCities = (state: RootState) => state.doorman.doormanCities.entities.list;

const getDoormanTowns = (state: RootState) => state.doorman.doormanTowns.entities.list;

const getDoormanUnits = (state: RootState) => state.doorman.doormanUnits.entities.list;

const getDoormanLines = (state: RootState) => state.doorman.doormanLines.entities.list;

const getDoormanStations = (state: RootState) => state.doorman.doormanStations.entities.list;

const fetchDoormanCitiesStatus = (state: RootState) => state.doorman.doormanCities.fetchStatus;

const fetchDoormanTownsStatus = (state: RootState) => state.doorman.doormanTowns.fetchStatus;

export default {
  getDoormanMansions,
  getDoormanCities,
  getDoormanUnits,
  getDoormanTowns,
  getDoormanLines,
  getDoormanStations,
  fetchDoormanCitiesStatus,
  fetchDoormanTownsStatus,
};
