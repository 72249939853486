import { combineReducers } from 'redux';
import { ActionType, getType } from 'typesafe-actions';
import actions from '@/state/modules/assessment/actions';
import { State } from '@/state/store';
import { FetchStatus } from '@/models/enum/app';
import produce from 'immer';
import { Assessment } from 'openapi/kusabi-frontgw-spec';
import { AssessmentFormValue } from '@/models/request/assessment';

type Action = ActionType<typeof actions>;

interface CreateAssessmentState extends State {
  fetchStatus: FetchStatus;
  errors: string[];
  entities: Partial<Assessment>;
}

const createAssessmentInitialState: CreateAssessmentState = {
  fetchStatus: FetchStatus.UNSET,
  errors: [],
  entities: {},
};

export interface AssessmentFormValueState {
  assessmentFormValue: Partial<AssessmentFormValue>;
}

export interface PersonalInfoValueState {
  detail: {
    lastName: string | null;
    firstName: string | null;
    lastNameKana: string | null;
    firstNameKana: string | null;
    mailAddress: string | null;
    phoneNumber: string | null;
  } | null;
}

const personalInfoValueInitialState: PersonalInfoValueState = {
  detail: null,
};

const assessmentFormValueInitialState: AssessmentFormValueState = {
  assessmentFormValue: {},
};

const createAssessmentReducer = (
  state: CreateAssessmentState = createAssessmentInitialState,
  action: Action
): CreateAssessmentState =>
  produce(state, (draft) => {
    switch (action.type) {
      case getType(actions.createAssessment.request): {
        draft.fetchStatus = FetchStatus.LOADING;
        return draft;
      }
      case getType(actions.createAssessment.success): {
        draft.fetchStatus = FetchStatus.DONE;
        draft.entities = action.payload.result;
        return draft;
      }
      case getType(actions.createAssessment.failure): {
        draft.fetchStatus = FetchStatus.FAILED;
        draft.errors = [action.payload.error.message];
        return draft;
      }
      default:
        return state;
    }
  });

const assessmentFormValueReducer = (
  state: AssessmentFormValueState = assessmentFormValueInitialState,
  action: Action
): AssessmentFormValueState =>
  produce(state, (draft) => {
    switch (action.type) {
      case getType(actions.keepAssessmentFormValue): {
        draft.assessmentFormValue = action.payload;
        return draft;
      }
      case getType(actions.clearAssessmentFormValue): {
        draft = assessmentFormValueInitialState;
        return draft;
      }
      default:
        return state;
    }
  });

const personalInfoValueReducer = (
  state: PersonalInfoValueState = personalInfoValueInitialState,
  action: Action
): PersonalInfoValueState =>
  produce(state, (draft) => {
    switch (action.type) {
      case getType(actions.setPersonalInfoValue): {
        draft.detail = action.payload.detail;
        return draft;
      }
      case getType(actions.clearPersonalInfoValue): {
        draft.detail = null;
        return draft;
      }
      default:
        return state;
    }
  });

export default combineReducers({
  createAssessment: createAssessmentReducer,
  assessmentFormValue: assessmentFormValueReducer,
  personalInfoValue: personalInfoValueReducer,
});
