interface App {
  env: string;
  version: string;
  amplify: object;
  kusabiGatewayUrl: string;
  kusabiContentsUrl: string;
}

export const initAppConfig = (c: App) => {
  appConfig = c;
  return appConfig;
};

export let appConfig: App;
