import { combineReducers } from 'redux';
import { ActionType, getType } from 'typesafe-actions';
import actions from '@/state/modules/member/actions';
import { State } from '@/state/store';
import { FetchStatus } from '@/models/enum/app';
import produce from 'immer';
import { Member, ResponseRegisterMailAddress } from 'openapi/kusabi-frontgw-spec';

type Action = ActionType<typeof actions>;

interface MemberState extends State {
  fetchStatus: {
    status: FetchStatus;
  };
  errors: string[];
  entities: {
    result: Member;
  };
}

const memberInitialState: MemberState = {
  fetchStatus: {
    status: FetchStatus.UNSET,
  },
  errors: [],
  entities: {
    result: {
      id: 0,
      lastName: '',
      firstName: '',
      lastNameKana: '',
      firstNameKana: '',
      mailAddress: '',
      phoneNumber: '',
      annualIncome: 0,
      birthday: '',
      totalLoanAmount: 0,
      workPlace: '',
      serviceYears: 0,
      mailDelivery: true,
      note: '',
      awsCognitoId: '',
      createdAt: '',
      updatedAt: '',
      loanableAmount: 0,
      propertiesCount: 0,
    },
  },
};

interface GetMemberState extends State {
  fetchStatus: {
    status: FetchStatus;
  };
  errors: string[];
  entities: {
    result: Member;
  };
}

const getMemberInitialState: GetMemberState = {
  fetchStatus: {
    status: FetchStatus.UNSET,
  },
  errors: [],
  entities: {
    result: {
      id: 0,
      lastName: '',
      firstName: '',
      lastNameKana: '',
      firstNameKana: '',
      mailAddress: '',
      phoneNumber: '',
      annualIncome: 0,
      birthday: '',
      totalLoanAmount: 0,
      workPlace: '',
      serviceYears: 0,
      mailDelivery: true,
      note: '',
      awsCognitoId: '',
      ipAddress: '',
      createdAt: '',
      updatedAt: '',
      loanableAmount: 0,
      propertiesCount: 0,
    },
  },
};

interface UpdateMemberState extends State {
  fetchStatus: {
    status: FetchStatus;
  };
  errors: string[];
  entities: {
    result: Member;
  };
}

const updateMemberInitialState: UpdateMemberState = {
  fetchStatus: {
    status: FetchStatus.UNSET,
  },
  errors: [],
  entities: {
    result: {
      id: 0,
      lastName: '',
      firstName: '',
      lastNameKana: '',
      firstNameKana: '',
      mailAddress: '',
      phoneNumber: '',
      annualIncome: 0,
      birthday: '',
      totalLoanAmount: 0,
      workPlace: '',
      serviceYears: 0,
      mailDelivery: true,
      note: '',
      awsCognitoId: '',
      createdAt: '',
      updatedAt: '',
      loanableAmount: 0,
      propertiesCount: 0,
    },
  },
};

interface DeleteMemberState extends State {
  fetchStatus: {
    status: FetchStatus;
  };
  entities: [];
  errors: string[];
}

const deleteMemberInitialState: DeleteMemberState = {
  fetchStatus: {
    status: FetchStatus.UNSET,
  },
  entities: [],
  errors: [],
};

interface GetIsRegisteredMailAddressState extends State {
  fetchStatus: FetchStatus;
  errors: string[];
  entities: {
    result: ResponseRegisterMailAddress;
  };
}

const getIsRegisteredMailAddressInitialState: GetIsRegisteredMailAddressState = {
  fetchStatus: FetchStatus.UNSET,
  errors: [],
  entities: {
    result: { isRegistered: false },
  },
};

const memberReducer = (state: MemberState = memberInitialState, action: Action): MemberState =>
  produce(state, (draft) => {
    switch (action.type) {
      case getType(actions.createMember.request): {
        draft.fetchStatus.status = FetchStatus.LOADING;
        return draft;
      }
      case getType(actions.createMember.success): {
        draft.fetchStatus.status = FetchStatus.DONE;
        draft.entities.result = action.payload.result;
        return draft;
      }
      case getType(actions.createMember.failure): {
        draft.fetchStatus.status = FetchStatus.FAILED;
        draft.errors = [action.payload.error.message];
        return draft;
      }
      default:
        return state;
    }
  });

const getMemberReducer = (state: GetMemberState = getMemberInitialState, action: Action): GetMemberState =>
  produce(state, (draft) => {
    switch (action.type) {
      case getType(actions.getMember.request): {
        draft.fetchStatus.status = FetchStatus.LOADING;
        return draft;
      }
      case getType(actions.getMember.success): {
        draft.fetchStatus.status = FetchStatus.DONE;
        draft.entities.result = action.payload.result;
        return draft;
      }
      case getType(actions.getMember.failure): {
        draft.fetchStatus.status = FetchStatus.FAILED;
        draft.errors = [action.payload.error.message];
        return draft;
      }
      default:
        return state;
    }
  });

const updateMemberReducer = (state: UpdateMemberState = updateMemberInitialState, action: Action): UpdateMemberState =>
  produce(state, (draft) => {
    switch (action.type) {
      case getType(actions.updateMember.request): {
        draft.fetchStatus.status = FetchStatus.LOADING;
        return draft;
      }
      case getType(actions.updateMember.success): {
        draft.fetchStatus.status = FetchStatus.DONE;
        draft.entities.result = action.payload.result;
        return draft;
      }
      case getType(actions.updateMember.failure): {
        draft.fetchStatus.status = FetchStatus.FAILED;
        draft.errors = [action.payload.error.message];
        return draft;
      }
      case getType(actions.updateMember.cancel): {
        draft.fetchStatus.status = FetchStatus.UNSET;
        return draft;
      }
      default:
        return state;
    }
  });

const deleteMemberReducer = (state: DeleteMemberState = deleteMemberInitialState, action: Action): DeleteMemberState =>
  produce(state, (draft) => {
    switch (action.type) {
      case getType(actions.deleteMember.request): {
        draft.fetchStatus.status = FetchStatus.LOADING;
        return draft;
      }
      case getType(actions.deleteMember.success): {
        draft.fetchStatus.status = FetchStatus.DONE;
        return draft;
      }
      case getType(actions.deleteMember.failure): {
        draft.fetchStatus.status = FetchStatus.FAILED;
        draft.errors = [action.payload.error.message];
        return draft;
      }
      default:
        return state;
    }
  });

const getIsRegisteredMailAddressReducer = (
  state: GetIsRegisteredMailAddressState = getIsRegisteredMailAddressInitialState,
  action: Action
): GetIsRegisteredMailAddressState =>
  produce(state, (draft) => {
    switch (action.type) {
      case getType(actions.getIsRegisteredMailAddress.request): {
        draft.fetchStatus = FetchStatus.LOADING;
        return draft;
      }
      case getType(actions.getIsRegisteredMailAddress.success): {
        draft.fetchStatus = FetchStatus.DONE;
        draft.entities.result = action.payload.result;
        return draft;
      }
      case getType(actions.getIsRegisteredMailAddress.failure): {
        draft.fetchStatus = FetchStatus.FAILED;
        draft.errors = [action.payload.error.message];
        return draft;
      }
      case getType(actions.getIsRegisteredMailAddress.cancel): {
        draft.fetchStatus = FetchStatus.UNSET;
        return draft;
      }
      default:
        return state;
    }
  });

export default combineReducers({
  member: memberReducer,
  getMember: getMemberReducer,
  updateMember: updateMemberReducer,
  deleteMember: deleteMemberReducer,
  getIsRegisteredMailAddress: getIsRegisteredMailAddressReducer,
});
