import { apply, put, takeLatest } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import actions from '@/state/modules/assessment/actions';
import * as apis from '@/apis/gateway';
import { AxiosResponse } from 'axios';
import { Assessment } from 'openapi/kusabi-frontgw-spec';

function* createAssessmentAsync(action: ReturnType<typeof actions.createAssessment.request>) {
  try {
    const result: AxiosResponse<Assessment, any> = yield apply(
      apis.assessmentApi,
      apis.assessmentApi.createAssessment,
      [action.payload.assessment]
    );
    yield put(actions.createAssessment.success({ result: result.data }));
  } catch (err: any) {
    console.log(err);
    yield put(actions.createAssessment.failure({ error: new Error(err.message) }));
  }
}

const sagas = [takeLatest(getType(actions.createAssessment.request), createAssessmentAsync)];

export default sagas;
