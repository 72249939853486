export enum PostInquiries {
  REQUEST = '@inquiry/postInquiries/request',
  SUCCESS = '@inquiry/postInquiries/success',
  FAILED = '@inquiry/postInquiries/failed',
}

export enum UpdateInquiries {
  REQUEST = '@inquiry/updateInquiries/request',
  SUCCESS = '@inquiry/updateInquiries/success',
  FAILED = '@inquiry/updateInquiries/failed',
}
