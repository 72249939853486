import { AssessmentFormValue } from '@/models/request/assessment';
import actions from '@/state/modules/assessment/actions';
import { Assessment } from 'openapi/kusabi-frontgw-spec';
import { PersonalInfoValueState as PersonalInfoValue } from '@/state/modules/assessment/reducers';

const createAssessment = (assessment: Assessment) => actions.createAssessment.request({ assessment });

const keepAssessmentFormValue = (assessmentFormValue: AssessmentFormValue) =>
  actions.keepAssessmentFormValue(assessmentFormValue);

const clearAssessmentFormValue = () => actions.clearAssessmentFormValue();

const personalInfoValue = (personalInfoValue: PersonalInfoValue) => actions.setPersonalInfoValue(personalInfoValue);
const clearPersonalInfoValue = () => actions.clearPersonalInfoValue();

export default {
  createAssessment,
  personalInfoValue,
  keepAssessmentFormValue,
  clearAssessmentFormValue,
  clearPersonalInfoValue,
};
