import { RootState } from '@/state/store';

const createAssessment = (state: RootState) => state.assessment.createAssessment.entities;

const fetchAssessmentFormValue = (state: RootState) => state.assessment.assessmentFormValue.assessmentFormValue;

const fetchPersonalInfoDetailValue = (state: RootState) => state.assessment.personalInfoValue.detail;

export default {
  createAssessment,
  fetchAssessmentFormValue,
  fetchPersonalInfoDetailValue,
};
