import { RootState } from '@/state/store';

const getSalesProperties = (state: RootState) => state.buyer.salesProperties.entities;

const getContractsProperties = (state: RootState) => state.buyer.contractsProperties.entities;

const getformValues = (state: RootState) => state.buyer.buyerUserInfo;

const getContractPropertyDetail = (state: RootState) => state.buyer.getContractPropertyDetail.entities;

const getSalePropertyDetail = (state: RootState) => state.buyer.getSalePropertyDetail.entities;

const getSalePropertyDetailStatus = (state: RootState) => state.buyer.getSalePropertyDetail.fetchStatus;

const savePath = (state: RootState) => state.buyer.savePath;

const getAreaMappings = (state: RootState) => state.buyer.getAreaMappings;

const isTrialMode = (state: RootState) => state.buyer.buyerTop.isTrialMode;

export default {
  getSalesProperties,
  getContractsProperties,
  getformValues,
  getContractPropertyDetail,
  getSalePropertyDetailStatus,
  getSalePropertyDetail,
  savePath,
  getAreaMappings,
  isTrialMode,
};
