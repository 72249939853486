import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { all } from 'redux-saga/effects';
import logger from 'redux-logger';
import { PRODUCTION } from '@/constants/constants';
import doormanReducer, * as doorman from '@/state/modules/doorman';
import buyerReducer, * as buyer from '@/state/modules/buyer';
import inquiryReducer, * as inquiry from '@/state/modules/inquiry';
import memberReducer, * as member from '@/state/modules/member';
import assessmentReducer, * as assessment from '@/state/modules/assessment';
import loanableAmountsReducer, * as loanableAmounts from '@/state/modules/loanableAmounts';

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/ban-types
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: Function;
  }
}

export type RootState = {
  doorman: ReturnType<typeof doormanReducer>;
  buyer: ReturnType<typeof buyerReducer>;
  inquiry: ReturnType<typeof inquiryReducer>;
  member: ReturnType<typeof memberReducer>;
  assessment: ReturnType<typeof assessmentReducer>;
  loanableAmounts: ReturnType<typeof loanableAmountsReducer>;
};

export interface State {
  fetchStatus: any;
  errors: any;
  entities: any;
}

function* rootSaga() {
  yield all([...doorman.sagas]);
  yield all([...buyer.sagas]);
  yield all([...inquiry.sagas]);
  yield all([...member.sagas]);
  yield all([...assessment.sagas]);
  yield all([...loanableAmounts.sagas]);
}

const rootReducer = combineReducers({
  doorman: doormanReducer,
  buyer: buyerReducer,
  inquiry: inquiryReducer,
  member: memberReducer,
  assessment: assessmentReducer,
  loanableAmounts: loanableAmountsReducer,
});

const sagaMiddleware = createSagaMiddleware();

let composeEnhancers: any = compose;
if (typeof window !== 'undefined') {
  // https://www.white-space.work/gatsby-cannot-read-property-window-of-undefined/
  composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
}

export default function configureStore(initialStete?: RootState) {
  const middleweres: any[] = [sagaMiddleware];
  if (process.env.NODE_ENV !== PRODUCTION) {
    middleweres.push(logger);
  }
  const enhancers = composeEnhancers(applyMiddleware(...middleweres));
  const store = createStore(rootReducer, initialStete, enhancers);
  sagaMiddleware.run(rootSaga);
  return store;
}
