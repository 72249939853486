import { RootState } from '@/state/store';

const getMember = (state: RootState) => state.member.getMember.entities.result;

const fetchUpdateMemberStatus = (state: RootState) => state.member.updateMember.fetchStatus;

const deleteMemberStatus = (state: RootState) => state.member.deleteMember.fetchStatus;

const fetchIsRegisteredMailAddressStatus = (state: RootState) => state.member.getIsRegisteredMailAddress.fetchStatus;

const fetchIsRegisteredMailAddress = (state: RootState) => state.member.getIsRegisteredMailAddress.entities;

export default {
  getMember,
  fetchUpdateMemberStatus,
  deleteMemberStatus,
  fetchIsRegisteredMailAddressStatus,
  fetchIsRegisteredMailAddress,
};
