import { apply, put, takeLatest } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import actions from '@/state/modules/buyer/actions';
import * as apis from '@/apis/gateway';
import { AreaMappings, ContractsProperties, Property, SalesProperties } from 'openapi/kusabi-frontgw-spec';
import { AxiosResponse } from 'axios';

function* getSalePropertiesAsync(action: ReturnType<typeof actions.getSaleProperties.request>) {
  try {
    const param = action.payload.params;
    const result: AxiosResponse<SalesProperties, any> = yield apply(
      apis.propertiesApi,
      apis.propertiesApi.getSaleProperties,
      [
        param.maxLoanAmount,
        param.isBuyable,
        param.area,
        param.sort,
        param.limit,
        param.offset,
        param.firstPublishedAtFrom,
        param.firstPublishedAtTo,
        param.priceUpdatedAtFrom,
        param.priceUpdatedAtTo,
      ]
    );
    yield put(actions.getSaleProperties.success({ result: result.data }));
  } catch (err: any) {
    console.log(err);
    yield put(actions.getSaleProperties.failure({ error: new Error(err.message) }));
  }
}

function* getContractPropertiesAsync(action: ReturnType<typeof actions.getContractProperties.request>) {
  try {
    const param = action.payload.params;
    const result: AxiosResponse<ContractsProperties, any> = yield apply(
      apis.propertiesApi,
      apis.propertiesApi.getContractProperties,
      [param.area, param.sort, param.limit, param.offset]
    );
    yield put(actions.getContractProperties.success({ result: result.data }));
  } catch (err: any) {
    console.log(err);
    yield put(actions.getContractProperties.failure({ error: new Error(err.message) }));
  }
}

function* getContractPropertyDetailAsync(action: ReturnType<typeof actions.getContractPropertyDetail.request>) {
  try {
    const result: AxiosResponse<Property> = yield apply(
      apis.propertiesApi,
      apis.propertiesApi.getContractPropertyDetail,
      [action.payload.propertyId]
    );
    yield put(actions.getContractPropertyDetail.success({ result: result.data }));
  } catch (err: any) {
    console.log(err);
    yield put(actions.getContractPropertyDetail.failure({ error: new Error(err.message) }));
  }
}

function* getSalePropertyDetailAsync(action: ReturnType<typeof actions.getSalePropertyDetail.request>) {
  try {
    const result: AxiosResponse<Property> = yield apply(apis.propertiesApi, apis.propertiesApi.getSalePropertyDetail, [
      action.payload.propertyId,
    ]);
    yield put(actions.getSalePropertyDetail.success({ result: result.data }));
  } catch (err: any) {
    console.log(err);
    yield put(actions.getSalePropertyDetail.failure({ error: new Error(err.message) }));
  }
}

function* getAreaMappingsAsync() {
  try {
    const result: AxiosResponse<AreaMappings, any> = yield apply(
      apis.areaMappingsApi,
      apis.areaMappingsApi.getAreaMappings,
      []
    );
    yield put(actions.getAreaMappings.success({ result: result.data }));
  } catch (err: any) {
    console.log(err);
    yield put(actions.getAreaMappings.failure({ error: new Error(err.message) }));
  }
}

const sagas = [
  takeLatest(getType(actions.getSaleProperties.request), getSalePropertiesAsync),
  takeLatest(getType(actions.getContractProperties.request), getContractPropertiesAsync),
  takeLatest(getType(actions.getContractPropertyDetail.request), getContractPropertyDetailAsync),
  takeLatest(getType(actions.getSalePropertyDetail.request), getSalePropertyDetailAsync),
  takeLatest(getType(actions.getAreaMappings.request), getAreaMappingsAsync),
];

export default sagas;
