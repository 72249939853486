import { createAsyncAction } from 'typesafe-actions';
import {
  CreateMember,
  DeleteMember,
  GetIsRegisteredMailAddress,
  GetMember,
  UpdateMember,
} from '@/state/modules/member/types';
import { Member, ResponseRegisterMailAddress } from 'openapi/kusabi-frontgw-spec';

const createMember = createAsyncAction(CreateMember.REQUEST, CreateMember.SUCCESS, CreateMember.FAILED)<
  {
    params: Member;
  },
  {
    result: Member;
  },
  { error: Error }
>();

const getMember = createAsyncAction(GetMember.REQUEST, GetMember.SUCCESS, GetMember.FAILED)<
  { '': '' },
  {
    result: Member;
  },
  { error: Error }
>();

const updateMember = createAsyncAction(
  UpdateMember.REQUEST,
  UpdateMember.SUCCESS,
  UpdateMember.FAILED,
  UpdateMember.CLEAR
)<
  {
    params: Member;
  },
  {
    result: Member;
  },
  { error: Error }
>();

const deleteMember = createAsyncAction(DeleteMember.REQUEST, DeleteMember.SUCCESS, DeleteMember.FAILED)<
  void,
  void,
  { error: Error }
>();

const getIsRegisteredMailAddress = createAsyncAction(
  GetIsRegisteredMailAddress.REQUEST,
  GetIsRegisteredMailAddress.SUCCESS,
  GetIsRegisteredMailAddress.FAILED,
  GetIsRegisteredMailAddress.CLEAR
)<
  {
    params: { mailAddress: string };
  },
  {
    result: ResponseRegisterMailAddress;
  },
  { error: Error }
>();

export default {
  createMember,
  getMember,
  updateMember,
  deleteMember,
  getIsRegisteredMailAddress,
};
