import actions from '@/state/modules/doorman/actions';
import { DoormanMansionsRequest } from 'openapi/kusabi-frontgw-spec';

const searchMansions = (params: DoormanMansionsRequest) => actions.searchMansions.request({ params });

const getCities = (params: number) => actions.getCities.request({ params });

const getTowns = (params: number) => actions.getTowns.request({ params });

const getUnits = (params: number) => actions.getUnits.request({ params });

const getLines = (params: number) => actions.getLines.request({ params });

const getStations = (params: number) => actions.getStations.request({ params });

export default {
  searchMansions,
  getCities,
  getTowns,
  getUnits,
  getLines,
  getStations,
};
