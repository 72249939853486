import { combineReducers } from 'redux';
import { ActionType, getType } from 'typesafe-actions';
import actions from '@/state/modules/loanableAmounts/actions';
import { State } from '@/state/store';
import { FetchStatus } from '@/models/enum/app';
import produce from 'immer';
import { LoanableAmountsProperty } from 'openapi/kusabi-frontgw-spec';

type Action = ActionType<typeof actions>;

interface GetLoanableAmountsState extends State {
  fetchStatus: FetchStatus;
  errors: string[];
  entities: Partial<LoanableAmountsProperty>;
}

const getLoanableAmountsInitialState: GetLoanableAmountsState = {
  fetchStatus: FetchStatus.UNSET,
  errors: [],
  entities: {},
};

const getLoanableAmountsReducer = (
  state: GetLoanableAmountsState = getLoanableAmountsInitialState,
  action: Action
): GetLoanableAmountsState =>
  produce(state, (draft) => {
    switch (action.type) {
      case getType(actions.getLoanableAmounts.request): {
        draft.fetchStatus = FetchStatus.LOADING;
        return draft;
      }
      case getType(actions.getLoanableAmounts.success): {
        draft.fetchStatus = FetchStatus.DONE;
        draft.entities = action.payload.result;
        return draft;
      }
      case getType(actions.getLoanableAmounts.failure): {
        draft.fetchStatus = FetchStatus.FAILED;
        draft.errors = [action.payload.error.message];
        return draft;
      }
      default:
        return state;
    }
  });

export default combineReducers({
  getLoanableAmounts: getLoanableAmountsReducer,
});
