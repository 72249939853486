import { combineReducers } from 'redux';
import { ActionType, getType } from 'typesafe-actions';
import actions from '@/state/modules/buyer/actions';
import { State } from '@/state/store';
import { FetchStatus } from '@/models/enum/app';
import produce from 'immer';
import { ContractsProperties, Property, SalesProperties, AreaMappings } from 'openapi/kusabi-frontgw-spec';

type Action = ActionType<typeof actions>;

interface BuyerSalesPropertiesState extends State {
  fetchStatus: FetchStatus;
  errors: string[];
  entities: SalesProperties;
}

const buyerSalesPropertiesInitialState: BuyerSalesPropertiesState = {
  fetchStatus: FetchStatus.UNSET,
  errors: [],
  entities: {
    salesProperties: [],
    salesPropertiesCount: 0,
  },
};

interface BuyerContractsPropertiesState extends State {
  fetchStatus: FetchStatus;
  errors: string[];
  entities: ContractsProperties;
}

const buyerContractsPropertiesInitialState: BuyerContractsPropertiesState = {
  fetchStatus: FetchStatus.UNSET,
  errors: [],
  entities: {
    contractsProperties: [],
    contractsPropertiesCount: 0,
  },
};

interface GetContractPropertyDetailState extends State {
  fetchStatus: FetchStatus;
  errors: string[];
  entities: Partial<Property>;
}

const getContractPropertyDetailInitialState: GetContractPropertyDetailState = {
  fetchStatus: FetchStatus.UNSET,
  errors: [],
  entities: {},
};

interface GetSalePropertyDetailState extends State {
  fetchStatus: FetchStatus;
  errors: string[];
  entities: Partial<Property>;
}

const getSalePropertyDetaillInitialState: GetSalePropertyDetailState = {
  fetchStatus: FetchStatus.UNSET,
  errors: [],
  entities: {},
};

interface GetAreaMappingsState extends State {
  fetchStatus: FetchStatus;
  errors: string[];
  entities: Partial<AreaMappings>;
}

const getAreaMappingsInitialState: GetAreaMappingsState = {
  fetchStatus: FetchStatus.UNSET,
  errors: [],
  entities: { areaMappings: [] },
};

interface BuyerTopState {
  isTrialMode: boolean;
}

const buyerTopInitialState: BuyerTopState = {
  isTrialMode: false,
};

const salesPropertiesReducer = (
  state: BuyerSalesPropertiesState = buyerSalesPropertiesInitialState,
  action: Action
): BuyerSalesPropertiesState =>
  produce(state, (draft) => {
    switch (action.type) {
      case getType(actions.getSaleProperties.request): {
        draft.fetchStatus = FetchStatus.LOADING;
        return draft;
      }
      case getType(actions.getSaleProperties.success): {
        draft.fetchStatus = FetchStatus.DONE;
        draft.entities = action.payload.result;
        return draft;
      }
      case getType(actions.getSaleProperties.failure): {
        draft.fetchStatus = FetchStatus.FAILED;
        draft.errors = [action.payload.error.message];
        return draft;
      }
      default:
        return state;
    }
  });

const contractsPropertiesReducer = (
  state: BuyerContractsPropertiesState = buyerContractsPropertiesInitialState,
  action: Action
): BuyerContractsPropertiesState =>
  produce(state, (draft) => {
    switch (action.type) {
      case getType(actions.getContractProperties.request): {
        draft.fetchStatus = FetchStatus.LOADING;
        return draft;
      }
      case getType(actions.getContractProperties.success): {
        draft.fetchStatus = FetchStatus.DONE;
        draft.entities = action.payload.result;
        return draft;
      }
      case getType(actions.getContractProperties.failure): {
        draft.fetchStatus = FetchStatus.FAILED;
        draft.errors = [action.payload.error.message];
        return draft;
      }
      default:
        return state;
    }
  });

const getContractPropertyDetailReducer = (
  state: GetContractPropertyDetailState = getContractPropertyDetailInitialState,
  action: Action
): GetContractPropertyDetailState =>
  produce(state, (draft) => {
    switch (action.type) {
      case getType(actions.getContractPropertyDetail.request): {
        draft.fetchStatus = FetchStatus.LOADING;
        return draft;
      }
      case getType(actions.getContractPropertyDetail.success): {
        draft.fetchStatus = FetchStatus.DONE;
        draft.entities = action.payload.result;
        return draft;
      }
      case getType(actions.getContractPropertyDetail.failure): {
        draft.fetchStatus = FetchStatus.FAILED;
        draft.errors = [action.payload.error.message];
        return draft;
      }
      default:
        return state;
    }
  });

const getSalePropertyDetailReducer = (
  state: GetSalePropertyDetailState = getSalePropertyDetaillInitialState,
  action: Action
): GetSalePropertyDetailState =>
  produce(state, (draft) => {
    switch (action.type) {
      case getType(actions.getSalePropertyDetail.request): {
        draft.fetchStatus = FetchStatus.LOADING;
        return draft;
      }
      case getType(actions.getSalePropertyDetail.success): {
        draft.fetchStatus = FetchStatus.DONE;
        draft.entities = action.payload.result;
        return draft;
      }
      case getType(actions.getSalePropertyDetail.failure): {
        draft.fetchStatus = FetchStatus.FAILED;
        draft.errors = [action.payload.error.message];
        return draft;
      }
      default:
        return state;
    }
  });

const getAreaMappingsReducer = (
  state: GetAreaMappingsState = getAreaMappingsInitialState,
  action: Action
): GetAreaMappingsState =>
  produce(state, (draft) => {
    switch (action.type) {
      case getType(actions.getAreaMappings.request): {
        draft.fetchStatus = FetchStatus.LOADING;
        return draft;
      }
      case getType(actions.getAreaMappings.success): {
        draft.fetchStatus = FetchStatus.DONE;
        draft.entities = action.payload.result;
        return draft;
      }
      case getType(actions.getAreaMappings.failure): {
        draft.fetchStatus = FetchStatus.FAILED;
        draft.errors = [action.payload.error.message];
        return draft;
      }
      default:
        return state;
    }
  });

const buyerTopReducer = (state: BuyerTopState = buyerTopInitialState, action: Action): BuyerTopState =>
  produce(state, (draft) => {
    switch (action.type) {
      case getType(actions.changeTrialMode): {
        draft.isTrialMode = action.payload;
        return draft;
      }
      default:
        return state;
    }
  });

/***************************
 会員登録情報
 ***************************/

export interface BuyerUserInfoState {
  firstName: string;
  lastName: string;
  firstNameKana: string;
  lastNameKana: string;
  email: string;
  tel: string;
  password: string;
  annualIncome: string;
  birthYear: string;
  birthMonth: string;
  birthDay: string;
  totalLoanAamount: string;
  workPlace: string;
  serviceYears: string;
}

const buyerUserInfoInitialState: BuyerUserInfoState = {
  firstName: '',
  lastName: '',
  firstNameKana: '',
  lastNameKana: '',
  email: '',
  tel: '',
  password: '',
  annualIncome: '',
  birthYear: '',
  birthMonth: '',
  birthDay: '',
  totalLoanAamount: '',
  workPlace: '',
  serviceYears: '',
};

const buyerUserInfoReducer = (
  state: BuyerUserInfoState = buyerUserInfoInitialState,
  action: Action
): BuyerUserInfoState =>
  produce(state, (draft) => {
    switch (action.type) {
      case getType(actions.changeAdvertiserValue): {
        draft = action.payload;
        return draft;
      }
      default:
        return state;
    }
  });

export interface SavePathState {
  redirectDist?: string;
}

const savePathInitialState: SavePathState = {};

const savePathReducer = (state: SavePathState = savePathInitialState, action: Action): SavePathState =>
  produce(state, (draft) => {
    switch (action.type) {
      case getType(actions.savePath): {
        draft = action.payload;
        return draft;
      }
      default:
        return state;
    }
  });

export default combineReducers({
  salesProperties: salesPropertiesReducer,
  contractsProperties: contractsPropertiesReducer,
  buyerUserInfo: buyerUserInfoReducer,
  getContractPropertyDetail: getContractPropertyDetailReducer,
  getSalePropertyDetail: getSalePropertyDetailReducer,
  savePath: savePathReducer,
  getAreaMappings: getAreaMappingsReducer,
  buyerTop: buyerTopReducer,
});
