import { range } from '@/common/utilities';
import { string } from 'yup';
import { YupErrorMessage } from '@/constants/messages';
import { InquiryType } from 'openapi/kusabi-frontgw-spec';

export const PRODUCTION = 'production';
export const DEVELOPMENT = 'development';

/**
 * 間取り
 */
export const FloorPlan = {
  ONE_ROOM: 'ワンルーム',
  K_1: '1K',
  DK_1: '1DK',
  LK_1: '1LK',
  SK_1: '1SK',
  SDK_1: '1SDK',
  SLK_1: '1SLK',
  LDK_1: '1LDK',
  LDK_1_A: '1SLDK',
  K_2: '2K',
  DK_2: '2DK',
  LK_2: '2LK',
  SK_2: '2SK',
  SDK_2: '2SDK',
  SLK_2: '2SLK',
  LDK_2: '2LDK',
  LDK_2_A: '2SLDK',
  K_3: '3K',
  DK_3: '3DK',
  LK_3: '3LK',
  SK_3: '3SK',
  SDK_3: '3SDK',
  SLK_3: '3SLK',
  LDK_3: '3LDK',
  LDK_3_A: '3SLDK',
  K_4: '4K',
  DK_4: '4DK',
  LK_4: '4LK',
  SK_4: '4SK',
  SDK_4: '4SDK',
  SLK_4: '4SLK',
  LDK_4: '4LDK',
  LDK_4_A: '4SLDK',
  K_5: '5K',
  DK_5: '5DK',
  LK_5: '5LK',
  SK_5: '5SK',
  SDK_5: '5SDK',
  SLK_5: '5SLK',
  LDK_5: '5LDK',
  LDK_5_A: '5SLDK',
} as const;
export const FloorPlanArray = Object.entries(FloorPlan).map(([key, value]) => ({ key, value }));

/**
 * 建物構造
 */
export const Structure = {
  WOODEN: '木造',
  BLOCK: 'ブロック',
  STEEL: '鉄骨造',
  RC: '鉄筋コンクリート',
  SRC: '鉄骨鉄筋コンクリート',
  PC: 'プレキャストコンクリート',
  HPC: '鉄骨プレキャストコンクリート',
  LIGHT_GAUGE_STEEL: '軽量鉄骨',
  OTHER: 'その他',
} as const;
export const StructureArray = Object.entries(Structure).map(([key, value]) => ({ key, value }));

/**
 * 必須項目エラーメッセージ
 */
export const VALID_PROPERTY_NAME_MESSAGE = '必須項目です';

/**
 * password形式入力エラーメッセージ
 */
export const PASSWORD_TYPE =
  'パスワードに利用可能な文字は半角数字、半角英小文字、半角英大文字、および特殊文字 ^$*.[]{}()?-"!@#%/¥,><\':;|_~`+= です';

/**
 * 確認用passwordがpasswordと異なる場合のエラーメッセージ
 */
export const INCONSISTENT_PASSWORD = 'パスワード入力とパスワード確認入力が一致しません';

/**
 * 西暦の一覧
 */
export const Years = range(1950, new Date().getFullYear());

/**
 * 問合せのご用件一覧
 */
export const InquiryRequestList = ['売却について', 'AI査定について', '面談希望（電話・オンライン含む）', 'その他'];

/**
 * 都道府県とIDの一覧
 */
export const Prefectures = [
  { id: 13, name: '東京都' },
  { id: 11, name: '埼玉県' },
  { id: 12, name: '千葉県' },
  { id: 14, name: '神奈川県' },
];

/**
 * 全角ひらがな正規表現
 */
export const REGEX_HIRAGANA_ZENKAKU = /^[ぁ-んー]+$/;

/**
 *  パスワード正規表現
 */
export const REGEX_PASSWORD = /^[A-Za-z0-9^$*.[\]{}()?\-"!@#%/,><':;|_~`+=]+$/;

/**
 * 電話番号正規表現
 */
export const REGEXP_PHONE = /^0\d{9,10}$/;

/**
 * 電話番号正規表現　ハイフン無し
 */
export const REGEX_PHONE_ONLY_NUMBER = /^\d{1,11}$/;

/**
 * 専有面積正規表現
 */
export const REGEX_UNITSIZE = /^\d{1,3}(\.[0-9]{1,2})?$/;

/**
 * 所在階正規表現
 */
export const REGEX_STORY = /^(B[1-5]|-[1-5]|[1-9][0-9]|[1-9])$/;

/**
 * 認証コード正規表現
 */
export const REGEX_VERIFY_CODE = /^[\d]{6}$/;

/**
 * 比率正規表現
 */
export const REGEX_RATE = /^\d+(\.\d{1,2})?$/;

/**
 * パスワードのscheme
 */
export const PASSWORD_VALIDATOR = string()
  .required(YupErrorMessage.required)
  .min(10, YupErrorMessage.string.min)
  .max(99, YupErrorMessage.string.max)
  .matches(REGEX_PASSWORD, YupErrorMessage.string.pw)
  .test('password_email_matches', YupErrorMessage.string.pw_email_matches, (value, obj) => {
    if (value) {
      const emailValue = obj.parent.email;
      if (emailValue && typeof emailValue === 'string') {
        return emailValue !== value;
      }
    }
    return true;
  });

export const UNSELECTED = '選択してください';

/**
 * 問合せ画面 ご用件のセレクトボックスに表示する一覧
 */
export const InquiryRequestContents = {
  seller: ['売却について', 'AI査定について', '面談希望（電話・オンライン含む）', 'その他'],
  buyer: {
    // 未ログイン
    unAuthenticated: {
      [InquiryType.SELL as string]: ['売却について', 'AI査定について', '面談希望（電話・オンライン含む）', 'その他'],
      [InquiryType.BUY as string]: [
        '物件探しについて',
        '資金計画について',
        '借入について',
        '契約について',
        '面談希望（電話・オンライン含む）',
        'その他',
      ],
      [InquiryType.BUY_REPLACEMENT as string]: [
        '物件探しについて',
        '資金計画について',
        '借入について',
        '契約について',
        '売却について',
        'AI査定について',
        '面談希望（電話・オンライン含む）',
        'その他',
      ],
    },
    // ログイン済み
    authenticated: {
      [InquiryType.BUY as string]: [
        '物件探しについて',
        '資金計画について（シミュレーション詳細）',
        '借入について',
        '契約について',
        '税金・費用について',
        '面談希望（電話・オンライン含む）',
        'その他',
      ],
      [InquiryType.BUY_PROPERTY as string]: [
        '申込',
        '面談希望（電話・オンライン含む）',
        '物件について',
        '現地見学について',
        '資金計画について（シミュレーション詳細）',
        '借入について',
        '契約について',
        '税金・費用について',
        'その他',
      ],
    },
  },
} as const;

/**
 * クエリパラメーター名
 */
export const QueryStrings = {
  // 物件ID
  PROPERTY_ID: 'p',
  // 画面フォーマット(c:contract,s:sale)
  SCREEN_FORMAT: 'f',
  // 流入元情報の識別子
  PARTNER_ID: 'r',
} as const;

/**
 * セッションストレージのキー名
 * 他サイトのキー名と重複しないようにすること
 */
export const SessionStorageKey = {
  // 流入元情報の識別子
  PARTNER_ID: '@@kusabi/partnerId',
} as const;

/**
 * 画面フォーマット
 */
export const ScreenFormat = {
  contract: 'c',
  sale: 's',
};

/**
 * ブレイクポイント
 * px指定
 */
export const Breakpoints = {
  sp: 900,
  pc: 1366,
} as const;

/**
 * マップエリア
 */
export type AreaType =
  | 'toshin6ku'
  | 'jotoh'
  | 'josai'
  | 'jonan'
  | 'johoku'
  | 'kugai23'
  | 'kanagawa'
  | 'saitama'
  | 'chiba';

export const CAREER_MAILS: string[] = [
  'au.com',
  'biz.au.com',
  'ezweb.ne.jp',
  'biz.ezweb.ne.jp',
  'augps.ezweb.ne.jp',
  'ido.ne.jp',
  'uqmobile.jp',
  'docomo.ne.jp',
  'mopera.net',
  'softbank.ne.jp',
  'disney.ne.jp',
  'disneymobile.ne.jp',
  'vodafone.ne.jp',
  'ymobile.ne.jp',
  'y-mobile.ne.jp',
  'ymobile1.ne.jp',
  'emnet.ne.jp',
  'emobile.ne.jp',
  'emobile-s.ne.jp',
  'yahoo.ne.jp',
  'pdx.ne.jp',
  'willcom.com',
  'wcm.ne.jp',
  'jp-c.ne.jp',
  'jp-d.ne.jp',
  'jp-h.ne.jp',
  'jp-k.ne.jp',
  'jp-n.ne.jp',
  'jp-q.ne.jp',
  'jp-r.ne.jp',
  'jp-s.ne.jp',
  'jp-t.ne.jp',
  'sky.tu-ka.ne.jp',
  'sky.tkc.ne.jp',
  'sky.TKK.ne.jp',
];

export const ContactTel = '0120-998-965';
export const ContactMailAddress = 'contact@kusabi-cloud.com';
