import 'ress';

import React from 'react';
import { initAppConfig } from './src/config/app';
import { initGatewayApi } from './src/apis/gateway';
import { Amplify } from 'aws-amplify';
import { Provider } from 'react-redux';
import configureStore from '@/state/store';

export const onClientEntry = async () => {
  console.time('fetchConfig');
  const res = await fetch('/config.json');
  const body = await res.json();
  console.timeEnd('fetchConfig');

  const config = initAppConfig(body);
  console.info(config);

  initGatewayApi(config.kusabiGatewayUrl);
  Amplify.configure(config.amplify);
};

const store = configureStore();

export const wrapRootElement = ({ element }) => {
  onClientEntry();
  return <Provider store={store}>{element}</Provider>;
};

/**
 * https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/#onRouteUpdate
 */
export const onRouteUpdate = ({ prevLocation }) => {
  // ルートが変更されたときに遷移前のパスをグローバル変数に保持する
  window.previousPath = prevLocation ? prevLocation.pathname : null;
};
