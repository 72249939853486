import { combineReducers } from 'redux';
import { ActionType, getType } from 'typesafe-actions';
import actions from '@/state/modules/inquiry/actions';
import { State } from '@/state/store';
import { FetchStatus } from '@/models/enum/app';
import produce from 'immer';
import { Inquiry, InquiryType } from 'openapi/kusabi-frontgw-spec';

type Action = ActionType<typeof actions>;

interface InquiriesState extends State {
  fetchStatus: FetchStatus;
  errors: string[];
  entities: Inquiry;
}

const inquiriesInitialState: InquiriesState = {
  fetchStatus: FetchStatus.UNSET,
  errors: [],
  entities: {
    memberId: 0,
    lastName: '',
    firstName: '',
    lastNameKana: '',
    firstNameKana: '',
    mailAddress: '',
    phoneNumber: '',
    type: InquiryType.BUY,
    request: '',
    content: '',
  },
};

interface UpdateInquiriesState extends State {
  fetchStatus: FetchStatus;
  errors: string[];
  entities: Inquiry;
}

const updateInquiriesInitialState: UpdateInquiriesState = {
  fetchStatus: FetchStatus.UNSET,
  errors: [],
  entities: {
    memberId: 0,
    lastName: '',
    firstName: '',
    lastNameKana: '',
    firstNameKana: '',
    mailAddress: '',
    phoneNumber: '',
    type: InquiryType.BUY,
    request: '',
    content: '',
  },
};

const inquiriesReducer = (state: InquiriesState = inquiriesInitialState, action: Action): InquiriesState =>
  produce(state, (draft) => {
    switch (action.type) {
      case getType(actions.postInquiries.request): {
        draft.fetchStatus = FetchStatus.LOADING;
        return draft;
      }
      case getType(actions.postInquiries.success): {
        draft.fetchStatus = FetchStatus.DONE;
        draft.entities = action.payload.result;
        return draft;
      }
      case getType(actions.postInquiries.failure): {
        draft.fetchStatus = FetchStatus.FAILED;
        draft.errors = [action.payload.error.message];
        return draft;
      }
      default:
        return state;
    }
  });

const updateInquiriesReducer = (
  state: UpdateInquiriesState = updateInquiriesInitialState,
  action: Action
): UpdateInquiriesState =>
  produce(state, (draft) => {
    switch (action.type) {
      case getType(actions.updateInquiries.request): {
        draft.fetchStatus = FetchStatus.LOADING;
        return draft;
      }
      case getType(actions.updateInquiries.success): {
        draft.fetchStatus = FetchStatus.DONE;
        draft.entities = action.payload.result;
        return draft;
      }
      case getType(actions.updateInquiries.failure): {
        draft.fetchStatus = FetchStatus.FAILED;
        draft.errors = [action.payload.error.message];
        return draft;
      }
      default:
        return state;
    }
  });
export default combineReducers({
  inquiries: inquiriesReducer,
  updateInquiries: updateInquiriesReducer,
});
