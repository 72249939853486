import actions from '@/state/modules/inquiry/actions';
import { Inquiry, UpdateInquiryRequest } from 'openapi/kusabi-frontgw-spec';

const postInquiries = (params: Inquiry) => actions.postInquiries.request({ params });

const updateInquiries = (inquiryId: number, updateInquiryRequest: UpdateInquiryRequest) =>
  actions.updateInquiries.request({ inquiryId, updateInquiryRequest });

export default {
  postInquiries,
  updateInquiries,
};
