import { appConfig } from '@/config/app';
import { Breakpoints, QueryStrings, SessionStorageKey } from '@/constants/constants';

/**
 * null、undefined、空文字であればtrue
 * @param value 対象文字列
 */
export function isBlank(value: string | null) {
  return value === null || value.length === 0 || value === 'null' || value === 'undefined' || value == 'NaN';
}

/**
 * サロゲートペアが含まれるか判定する
 *
 * @remarks 含まれる/true 含まれない/false
 *
 * @param str
 */
export const containsSurrogatePairs = (str: string): boolean => {
  if (isBlank(str)) {
    return false;
  }
  return !!/[\uD800-\uDBFF][\uDC00-\uDFFF]/g.test(`${str}`);
};

export const range = (start: number, end: number): Array<number> => {
  return new Array(end - start + 1).fill(null).map((_, i) => i + start);
};

/**
 * S3オブジェクトキーをCDNパスにする
 * @param s3ObjectKey S3オブジェクトキー
 * @returns CDNパス
 */
export const cdnPath = (s3ObjectKey: string | undefined): string => {
  if (!s3ObjectKey) return '';
  return new URL(s3ObjectKey, appConfig.kusabiContentsUrl).toString();
};

/**
 * パートナーIDをセッションストレージに保存する
 * @param partnerId パートナーID(流入元情報)
 */
export const setPartneridForSessionStorage = (partnerId: string | undefined | null) => {
  if (partnerId && partnerId.length === 32) {
    // 32桁 = md5の文字列長
    sessionStorage.setItem(SessionStorageKey.PARTNER_ID, partnerId);
  }
};

/**
 * セッションストレージからパートナーIDを取得する
 */
export const getPartneridForSessionStorage = (): string | undefined => {
  return sessionStorage.getItem(SessionStorageKey.PARTNER_ID) ?? undefined;
};

/**
 * パートナーIDのクエリ文字列を生成する
 */
export const generateQueryStringPartnerId = (): string => {
  const item = sessionStorage.getItem(SessionStorageKey.PARTNER_ID);
  if (!item) {
    return '';
  } else {
    return new URLSearchParams({ [QueryStrings.PARTNER_ID]: item }).toString();
  }
};

/**
 * ピクセルをvwに変換する
 * @param px
 * @returns vw
 */
export const toVw = (px: number): string => {
  // 100 / 基準値 × px = vw
  return `${(100 / Breakpoints.pc) * px}vw`;
};
