import { apply, put, takeLatest } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import actions from '@/state/modules/inquiry/actions';
import * as apis from '@/apis/gateway';
import { Inquiry } from 'openapi/kusabi-frontgw-spec';
import { AxiosResponse } from 'axios';

function* postInquiriesAsync(action: ReturnType<typeof actions.postInquiries.request>) {
  try {
    const result: AxiosResponse<Inquiry, any> = yield apply(apis.inquiriesApi, apis.inquiriesApi.postInquiries, [
      action.payload.params,
    ]);
    yield put(actions.postInquiries.success({ result: result.data }));
  } catch (err: any) {
    console.log(err);
    yield put(actions.postInquiries.failure({ error: new Error(err.message) }));
  }
}

function* updateInquiriesAsync(action: ReturnType<typeof actions.updateInquiries.request>) {
  try {
    const result: AxiosResponse<Inquiry, any> = yield apply(apis.inquiriesApi, apis.inquiriesApi.updateInquiries, [
      action.payload.inquiryId,
      action.payload.updateInquiryRequest,
    ]);
    yield put(actions.updateInquiries.success({ result: result.data }));
  } catch (err: any) {
    console.log(err);
    yield put(actions.updateInquiries.failure({ error: new Error(err.message) }));
  }
}

const sagas = [
  takeLatest(getType(actions.postInquiries.request), postInquiriesAsync),
  takeLatest(getType(actions.updateInquiries.request), updateInquiriesAsync),
];

export default sagas;
