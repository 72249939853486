export enum CreateMember {
  REQUEST = '@member/createMember/request',
  SUCCESS = '@member/createMember/success',
  FAILED = '@member/createMember/failed',
}

export enum GetMember {
  REQUEST = '@member/getMember/request',
  SUCCESS = '@member/getMember/success',
  FAILED = '@member/getMember/failed',
}

export enum UpdateMember {
  REQUEST = '@member/updateMember/request',
  SUCCESS = '@member/updateMember/success',
  FAILED = '@member/updateMember/failed',
  CLEAR = '@member/updateMember/clear',
}

export enum DeleteMember {
  REQUEST = '@member/deleteMember/request',
  SUCCESS = '@member/deleteMember/success',
  FAILED = '@member/deleteMember/failed',
}

export enum GetIsRegisteredMailAddress {
  REQUEST = '@member/getIsRegisteredMailAddress/request',
  SUCCESS = '@member/getIsRegisteredMailAddress/success',
  FAILED = '@member/getIsRegisteredMailAddress/failed',
  CLEAR = '@member/getIsRegisteredMailAddress/clear',
}
