import { GetSalePropertiesParam, GetContractPropertiesParam } from '@/models/request/buyer';
import actions from '@/state/modules/buyer/actions';
import { BuyerUserInfoState, SavePathState } from './reducers';

const getSaleProperties = (params: GetSalePropertiesParam) => actions.getSaleProperties.request({ params });

const getContractProperties = (params: GetContractPropertiesParam) => actions.getContractProperties.request({ params });

const changeAdvertiserValue = (formValues: BuyerUserInfoState) => actions.changeAdvertiserValue(formValues);

const getContractPropertyDetail = (propertyId: number) => actions.getContractPropertyDetail.request({ propertyId });

const getSalePropertyDetail = (propertyId: number) => actions.getSalePropertyDetail.request({ propertyId });

const savePath = (params: SavePathState) => actions.savePath(params);

const getAreaMappings = () => actions.getAreaMappings.request({ '': '' });

const changeTrialMode = (isTrialMode: boolean) => actions.changeTrialMode(isTrialMode);

export default {
  getSaleProperties,
  getContractProperties,
  changeAdvertiserValue,
  getContractPropertyDetail,
  getSalePropertyDetail,
  savePath,
  getAreaMappings,
  changeTrialMode,
};
