import { createAsyncAction } from 'typesafe-actions';
import { SearchMansions, GetCities, GetTowns, GetUnits, GetLines, GetStations } from '@/state/modules/doorman/types';
import {
  DoormanTowns,
  DoormanCities,
  DoormanMansions,
  DoormanMansionsRequest,
  DoormanUnits,
  DoormanLines,
  DoormanStations,
} from 'openapi/kusabi-frontgw-spec';

const searchMansions = createAsyncAction(SearchMansions.REQUEST, SearchMansions.SUCCESS, SearchMansions.FAILED)<
  {
    params: DoormanMansionsRequest;
  },
  { result: DoormanMansions },
  { error: Error }
>();

const getCities = createAsyncAction(GetCities.REQUEST, GetCities.SUCCESS, GetCities.FAILED)<
  {
    params: number;
  },
  { result: DoormanCities },
  { error: Error }
>();

const getTowns = createAsyncAction(GetTowns.REQUEST, GetTowns.SUCCESS, GetTowns.FAILED)<
  {
    params: number;
  },
  { result: DoormanTowns },
  { error: Error }
>();

const getUnits = createAsyncAction(GetUnits.REQUEST, GetUnits.SUCCESS, GetUnits.FAILED)<
  {
    params: number;
  },
  { result: DoormanUnits },
  { error: Error }
>();

const getLines = createAsyncAction(GetLines.REQUEST, GetLines.SUCCESS, GetLines.FAILED)<
  {
    params: number;
  },
  { result: DoormanLines },
  { error: Error }
>();

const getStations = createAsyncAction(GetStations.REQUEST, GetStations.SUCCESS, GetStations.FAILED)<
  {
    params: number;
  },
  { result: DoormanStations },
  { error: Error }
>();

export default {
  searchMansions,
  getCities,
  getTowns,
  getUnits,
  getLines,
  getStations,
};
