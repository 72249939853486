import {
  Configuration,
  InquiriesApi,
  DoormanApi,
  MemberApi,
  PropertiesApi,
  AssessmentApi,
  LoanableAmountsApi,
  AreamappingsApi,
} from 'openapi/kusabi-frontgw-spec';
import { Auth } from '@aws-amplify/auth';

export const initGatewayApi = (baseUrl: string) => {
  const openApiConfig = new Configuration({
    basePath: baseUrl,
    accessToken: () =>
      Auth.currentSession()
        .then((session) => {
          return session.getAccessToken().getJwtToken();
        })
        .catch((err) => {
          console.error(err);
          return '';
        }),
  });

  inquiriesApi = new InquiriesApi(openApiConfig);
  doormanApi = new DoormanApi(openApiConfig);
  memberApi = new MemberApi(openApiConfig);
  propertiesApi = new PropertiesApi(openApiConfig);
  assessmentApi = new AssessmentApi(openApiConfig);
  loanableAmountsApi = new LoanableAmountsApi(openApiConfig);
  areaMappingsApi = new AreamappingsApi(openApiConfig);
};
export let inquiriesApi: InquiriesApi;
export let doormanApi: DoormanApi;
export let memberApi: MemberApi;
export let propertiesApi: PropertiesApi;
export let assessmentApi: AssessmentApi;
export let loanableAmountsApi: LoanableAmountsApi;
export let areaMappingsApi: AreamappingsApi;
