import { combineReducers } from 'redux';
import { ActionType, getType } from 'typesafe-actions';
import actions from '@/state/modules/doorman/actions';
import { State } from '@/state/store';
import { FetchStatus } from '@/models/enum/app';
import produce from 'immer';
import {
  DoormanTowns,
  DoormanCities,
  DoormanMansions,
  DoormanUnits,
  DoormanLines,
  DoormanStations,
} from 'openapi/kusabi-frontgw-spec';

type Action = ActionType<typeof actions>;

interface DoormanMansionState extends State {
  fetchStatus: {
    status: FetchStatus;
  };
  errors: string[];
  entities: {
    list: DoormanMansions;
  };
}

interface DoormanCitiesState extends State {
  fetchStatus: {
    status: FetchStatus;
  };
  errors: string[];
  entities: {
    list: DoormanCities;
  };
}

interface DoormanTownsState extends State {
  fetchStatus: {
    status: FetchStatus;
  };
  errors: string[];
  entities: {
    list: DoormanTowns;
  };
}

interface DoormanUnitsState extends State {
  fetchStatus: {
    status: FetchStatus;
  };
  errors: string[];
  entities: {
    list: DoormanUnits;
  };
}

interface DoormanLinesState extends State {
  fetchStatus: {
    status: FetchStatus;
  };
  errors: string[];
  entities: {
    list: DoormanLines;
  };
}

interface DoormanStationsState extends State {
  fetchStatus: {
    status: FetchStatus;
  };
  errors: string[];
  entities: {
    list: DoormanStations;
  };
}

const doormanMansionStateInitialState: DoormanMansionState = {
  fetchStatus: {
    status: FetchStatus.UNSET,
  },
  errors: [],
  entities: {
    list: {},
  },
};

const doormanCitiesStateInitialState: DoormanCitiesState = {
  fetchStatus: {
    status: FetchStatus.UNSET,
  },
  errors: [],
  entities: {
    list: {},
  },
};

const doormanTownsStateInitialState: DoormanTownsState = {
  fetchStatus: {
    status: FetchStatus.UNSET,
  },
  errors: [],
  entities: {
    list: {},
  },
};

const doormanUnitsStateInitialState: DoormanUnitsState = {
  fetchStatus: {
    status: FetchStatus.UNSET,
  },
  errors: [],
  entities: {
    list: {},
  },
};

const doormanLinesStateInitialState: DoormanLinesState = {
  fetchStatus: {
    status: FetchStatus.UNSET,
  },
  errors: [],
  entities: {
    list: {},
  },
};

const doormanStationsStateInitialState: DoormanStationsState = {
  fetchStatus: {
    status: FetchStatus.UNSET,
  },
  errors: [],
  entities: {
    list: {},
  },
};

const doormanMansionReducer = (
  state: DoormanMansionState = doormanMansionStateInitialState,
  action: Action
): DoormanMansionState =>
  produce(state, (draft) => {
    switch (action.type) {
      case getType(actions.searchMansions.request): {
        draft.fetchStatus.status = FetchStatus.LOADING;
        return draft;
      }
      case getType(actions.searchMansions.success): {
        draft.fetchStatus.status = FetchStatus.DONE;
        draft.entities.list = action.payload.result;
        return draft;
      }
      case getType(actions.searchMansions.failure): {
        draft.fetchStatus.status = FetchStatus.FAILED;
        draft.errors = [action.payload.error.message];
        return draft;
      }
      default:
        return state;
    }
  });

const doormanCitiesReducer = (
  state: DoormanCitiesState = doormanCitiesStateInitialState,
  action: Action
): DoormanCitiesState =>
  produce(state, (draft) => {
    switch (action.type) {
      case getType(actions.getCities.request): {
        draft.fetchStatus.status = FetchStatus.LOADING;
        return draft;
      }
      case getType(actions.getCities.success): {
        draft.fetchStatus.status = FetchStatus.DONE;
        draft.entities.list = action.payload.result;
        return draft;
      }
      case getType(actions.getCities.failure): {
        draft.fetchStatus.status = FetchStatus.FAILED;
        draft.errors = [action.payload.error.message];
        return draft;
      }
      default:
        return state;
    }
  });

const doormanTownsReducer = (
  state: DoormanTownsState = doormanTownsStateInitialState,
  action: Action
): DoormanTownsState =>
  produce(state, (draft) => {
    switch (action.type) {
      case getType(actions.getTowns.request): {
        draft.fetchStatus.status = FetchStatus.LOADING;
        return draft;
      }
      case getType(actions.getTowns.success): {
        draft.fetchStatus.status = FetchStatus.DONE;
        draft.entities.list = action.payload.result;
        return draft;
      }
      case getType(actions.getTowns.failure): {
        draft.fetchStatus.status = FetchStatus.FAILED;
        draft.errors = [action.payload.error.message];
        return draft;
      }
      default:
        return state;
    }
  });

const doormanUnitsReducer = (
  state: DoormanUnitsState = doormanUnitsStateInitialState,
  action: Action
): DoormanUnitsState =>
  produce(state, (draft) => {
    switch (action.type) {
      case getType(actions.getUnits.request): {
        draft.fetchStatus.status = FetchStatus.LOADING;
        return draft;
      }
      case getType(actions.getUnits.success): {
        draft.fetchStatus.status = FetchStatus.DONE;
        draft.entities.list = action.payload.result;
        return draft;
      }
      case getType(actions.getUnits.failure): {
        draft.fetchStatus.status = FetchStatus.FAILED;
        draft.errors = [action.payload.error.message];
        return draft;
      }
      default:
        return state;
    }
  });

const doormanLinesReducer = (
  state: DoormanLinesState = doormanLinesStateInitialState,
  action: Action
): DoormanLinesState =>
  produce(state, (draft) => {
    switch (action.type) {
      case getType(actions.getLines.request): {
        draft.fetchStatus.status = FetchStatus.LOADING;
        return draft;
      }
      case getType(actions.getLines.success): {
        draft.fetchStatus.status = FetchStatus.DONE;
        draft.entities.list = action.payload.result;
        return draft;
      }
      case getType(actions.getLines.failure): {
        draft.fetchStatus.status = FetchStatus.FAILED;
        draft.errors = [action.payload.error.message];
        return draft;
      }
      default:
        return state;
    }
  });

const doormanStationsReducer = (
  state: DoormanStationsState = doormanStationsStateInitialState,
  action: Action
): DoormanStationsState =>
  produce(state, (draft) => {
    switch (action.type) {
      case getType(actions.getStations.request): {
        draft.fetchStatus.status = FetchStatus.LOADING;
        return draft;
      }
      case getType(actions.getStations.success): {
        draft.fetchStatus.status = FetchStatus.DONE;
        draft.entities.list = action.payload.result;
        return draft;
      }
      case getType(actions.getStations.failure): {
        draft.fetchStatus.status = FetchStatus.FAILED;
        draft.errors = [action.payload.error.message];
        return draft;
      }
      default:
        return state;
    }
  });

export default combineReducers({
  doormanMansion: doormanMansionReducer,
  doormanCities: doormanCitiesReducer,
  doormanTowns: doormanTownsReducer,
  doormanUnits: doormanUnitsReducer,
  doormanLines: doormanLinesReducer,
  doormanStations: doormanStationsReducer,
});
